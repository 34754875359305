.header-container {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 13vh;
  background: #ffffff;
  padding: 2vh 5%;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.11);
}

.header-container-content {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  z-index: -1;
}

.header-container-content a {
  height: 100%;
}

.header-container-content a img {
  height: 100%;
}

.header-container-content nav {
  display: flex;
  gap: 30px;
  align-items: center;
}

@media (max-width: 1000px) {
  .header-container-content nav {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

@media (max-width: 940px) {
  .header-container {
    height: 11vh;
  }

  .header-container-content nav {
    position: fixed;
    left: 0;
    top: 11.25vh;
    transform: translateX(-100vw);
    opacity: 0;
    z-index: -1;
    width: 100%;
    padding: 5vh 0vw;
    background: var(--white-color);
    display: flex;
    flex-direction: column;
    gap: 4vh;
    transition: 300ms;
  }

  .header-container-content .nav-active {
    transform: translateX(0vw);
    opacity: 1;
    -webkit-box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.11);
  }
}
