:root {
  --primary-green-color-with-opacity: rgba(155, 223, 0, 0.32);
  --primary-green-color: rgb(115, 165, 0);
  --secondary-green-color: #87aa62;
  --black-color: #1a3300;
  --primary-black-color: #000;
  --white-color: #fff;
  --primary-grey-color: #5f5f5f;
  --grey-color-with-opacity: rgba(115, 115, 115, 0.37);
  --black-color-with-opacity: rgba(35, 35, 35, 0.8);
  --secondary-grey-color: #e2e2e2;
  --grey-background: #f5f5f5;
  --preloader-grey-color: #d9d9d9;
}
