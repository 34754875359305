@import url("../../variables.css");

.navigation-url,
.navigation-url-active {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  position: relative;
}

.navigation-url {
  color: var(--primary-grey-color);
  transition: 300ms;
}

.navigation-url::after {
  transition: 300ms;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1.75vh;
  height: 8px;
  width: 100%;
  border-radius: 38px;
}

.navigation-url:hover::after {
  background: var(--primary-green-color-with-opacity);
}

.navigation-url-active {
  color: var(--black-color);
}

.navigation-url-active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1.75vh;
  background: var(--primary-green-color-with-opacity);
  height: 8px;
  width: 100%;
  border-radius: 38px;
}

@media (max-width: 940px) {
  .navigation-url-active::after,
  .navigation-url::after {
    display: none;
  }
}
