.contact {
  margin-top: 13vh;
  width: 100%;
  text-align: center;
  height: 100%;
}

.contact-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}

.contact-container-box {
  width: 50%;
  height: 100%;
}

.contact-container-box:first-child {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  min-height: 60vh;
}

.contact-container-box:last-child {
  height: auto;
  align-self: stretch;
}

.contact-container-box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 100%;
  padding: 10vh 5%;
}

.contact-container-box-content-data {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.contact-container-box-content-data:last-child {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.contact-container-box p {
  margin: 5px 0 0 0;
}

.contact-container-box iframe {
  border-top: none;
  height: 100%;
}

@media (max-width: 900px) {
  .contact {
    margin-top: 6vh;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-container-box {
    width: 100%;
  }

  .contact-container-box-content {
    width: 100%;
  }

  .contact-container-box iframe {
    border-top: none;
    height: 50vh;
  }
}
