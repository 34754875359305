.content-therapist {
  height: auto;
  text-align: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  justify-content: center;
  padding: 8vh 0;
}

.content-therapist a {
  all: unset;
  cursor: pointer;
  text-decoration: underline;
}

.content-therapist-margin {
  margin: 13vh auto 3vh auto;
  height: 100%;
}

.content-therapist-box:first-child {
  margin-left: 12px;
  margin-top: 30px;
  height: auto;
}

.content-therapist-box:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0vh 0 0vh 4vw;
}

.content-therapist-box p {
  color: var(--primary-grey-color);
  text-align: justify;
}

.spoilers-container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0% 4vh 0%;
  box-sizing: border-box;
  text-align: left;
  position: relative;
  bottom: 5vh;
}

@media (max-width: 1360px) {
  .content-therapist {
    padding: 0 5vw;
  }
}

@media (max-width: 1060px) {
  .content-therapist {
    padding: 0 5vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: center;
  }

  .content-therapist-box p {
    margin-left: 0;
  }

  .content-therapist-box:first-child {
    margin-top: 0vh;
    margin-left: 0px;
    width: 100%;
    height: auto;
  }

  .content-therapist-box:last-child {
    align-items: center;
    padding: 3vh 0 0vh;
  }

  .spoilers-container {
    padding: 0 5% 5vh 5%;
    box-sizing: border-box;
    text-align: center;
    bottom: 0vh;
  }
}
