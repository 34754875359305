@import url("../../variables.css");

.heading-black {
  color: var(--black-color);
}

.heading-white {
  color: var(--white-color);
}

.heading-black,
.heading-white {
  color: var(--black-color);
  position: relative;
  display: inline-block;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3vh;
}

.heading-black::after,
.heading-white::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1.75vh;
  background: var(--primary-green-color-with-opacity);
  height: 8px;
  width: 100%;
  border-radius: 38px;
}

@media (max-width: 940px) {
  .heading-black,
  .heading-white {
    font-size: 20px;
  }
}
