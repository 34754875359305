@import url("../../variables.css");

.textarea {
  color: var(--primary-grey-color);
  width: calc(90% + 10px);
  height: 10vh;
  font-weight: 400;
  font-size: 14px;
  padding: 10px 20px;
  text-align: left;
  border-radius: 25px;
  border: 2px solid var(--secondary-grey-color);
  outline: none;
  margin: 5px;
  resize: none;
  box-sizing: border-box;
}

@media (max-width: 460px) {
  .textarea {
    width: 100%;
  }
}
