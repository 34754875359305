@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
