@import url("../../variables.css");

.submit {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  padding: 14px 25px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 300ms;
  background: var(--primary-green-color);
  margin-top: 20px;
}

.submit:hover {
  background: var(--secondary-green-color);
}

.submit-opacity {
  background: var(--primary-green-color-with-opacity);
}

.submit-opacity:hover {
  background: var(--primary-green-color);
}
