@import url("../../variables.css");

.therapists-item {
  height: 65vh;
  max-height: 550px;
  position: relative;
  width: 100%;
}

.therapists-item::after {
  content: "";
  width: 12px;
  height: 60%;
  background: var(--primary-grey-color);
  position: absolute;
  z-index: 100;
  top: -6px;
  right: -6px;
  transition: 450ms;
}

.therapists-item::before {
  content: "";
  width: 60%;
  height: 12px;
  background: var(--primary-grey-color);
  position: absolute;
  z-index: 100;
  top: -6px;
  right: -6px;
  transition: 450ms;
}

.therapists-item .image-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.image-container::after {
  content: "";
  width: 12px;
  height: 60%;
  background: var(--secondary-green-color);
  position: absolute;
  z-index: 100;
  bottom: -6px;
  left: -6px;
  transition: 450ms;
}

.image-container::before {
  content: "";
  width: 60%;
  height: 12px;
  background: var(--secondary-green-color);
  position: absolute;
  z-index: 100;
  bottom: -6px;
  left: -6px;
  transition: 450ms;
}

.image-container img {
  height: 100%;
  border-radius: 10px;
}

.therapists-item ul {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  padding-left: 0;
  list-style-type: none;
  background: var(--black-color-with-opacity);
  transition: 400ms;
  opacity: 0;
  transform: translateY(-4%);
}

.therapists-item ul h5 {
  padding: 0;
  margin: 0px 0px 8px 0px;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0;
  color: var(--white-color);
}

.therapists-item ul li {
  max-width: 80%;
  font-size: 12px;
  color: var(--white-color);
  padding: 6px 0;
}

.therapists-item ul li:nth-child(1) {
  border-top: 0px;
}

.therapists-item:hover ul {
  opacity: 1;
}

.therapists-item:hover .image-container::before,
.therapists-item:hover::before {
  width: calc(100% + 12px);
}
.therapists-item:hover .image-container::after,
.therapists-item:hover::after {
  height: calc(100% + 12px);
}

@media (max-width: 1060px) {
  .therapists-white,
  .therapists-grey {
    padding: 2vh 5% 5vh 5%;
  }

  .image-container::before,
  .therapists-item::before {
    height: 8px;
  }

  .image-container::after,
  .therapists-item::after {
    width: 8px;
  }

  .image-container::after {
    bottom: -4px;
    left: -4px;
  }

  .therapists-item::before {
    top: -4px;
    right: -4px;
  }

  .therapists-item::after {
    top: -4px;
    right: -4px;
  }

  .image-container::before {
    bottom: -4px;
    left: -4px;
  }

  .therapists-item:hover .image-container::before,
  .therapists-item:hover::before {
    width: calc(100% + 8px);
  }
  .therapists-item:hover .image-container::after,
  .therapists-item:hover::after {
    height: calc(100% + 8px);
  }

  .therapists-item {
    height: 100%;
    max-height: 100%;
    position: relative;
    margin-top: 4vh;
    width: 100%;
    overflow: hidden;
  }

  .therapists-item .image-container {
    width: 100%;
    height: 90vw;
  }
  .image-container img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 520px) {
  .therapists-item {
    height: 100%;
    max-height: 100%;
    position: relative;
    margin-top: 4vh;
    width: 100%;
  }
  .therapists-item .image-container {
    width: 100%;
  }
  .image-container img {
    width: 100%;
    height: auto;
  }

  .therapists-item ul {
    display: none;
  }
}
