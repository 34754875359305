@import url("../../variables.css");

.therapists-white,
.therapists-grey {
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
}

.therapists-grey {
  background: var(--grey-background);
}

.therapists {
  all: unset;
  cursor: pointer;
  height: 100%;
}

.therapists-white .therapists-container {
  max-width: 1280px;
  width: 100%;
  margin: 13vh auto 0vh auto;
  padding: 5vh 5% 8vh 5%;
  box-sizing: border-box;
  height: 100%;
}

.therapists-grey .therapists-container {
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: 0vh auto 0vh auto;
  padding: 5vh 5% 8vh 5%;
  box-sizing: border-box;
}

.therapists-container-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3vw;
  height: 100%;
  margin-top: 3vh;
}

@media (max-width: 1060px) {
  .therapists-grey .therapists-container,
  .therapists-white .therapists-container {
    padding: 5vh 5% 4vh 5%;
    height: auto;
    margin: 0vh auto 0vh auto;
  }

  .therapists-container-content {
    height: 100%;
    flex-direction: column;
  }
}

@media (max-width: 1060px) {
  .therapists-container-content {
    margin-top: 0vh;
  }
}
