@import url("../../variables.css");

.name-value-pair {
  display: flex;
  gap: 3px;
  font-size: 15px;
  color: var(--black-color);
  letter-spacing: 0.2px;
}

.name-value-pair span {
  font-weight: 700;
}

.name-value-pair a {
  all: unset;
}

@media (max-width: 940px) {
  .name-value-pair a {
    cursor: pointer;
  }
}
