.content {
  text-align: center;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 5vh 5%;
  box-sizing: border-box;
}

.content-margin {
  margin: 13vh auto 3vh auto;
}

.content p {
  color: var(--primary-grey-color);
}

@media (max-width: 680px) {
  .content-margin {
    margin: 10vh auto 0vh auto;
  }
  .content p {
    text-align: justify;
  }
}
