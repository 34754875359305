@import url("../../variables.css");

@keyframes slideChange {
  0%,
  8% {
    opacity: 0;
  }
  15%,
  85% {
    opacity: 1;
  }
  92%,
  100% {
    opacity: 0;
  }
}

.topbar {
  width: 100%;
  height: 87vh;
  background-image: url("../../assets/img/office_one.webp");
  background-position: 30%;
  background-attachment: fixed;
  background-size: cover;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  margin-top: 13vh;
  box-sizing: border-box;
}

.topbar-carousel {
  width: 100%;
  background: var(--grey-color-with-opacity);
  color: var(--white-color);
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 3vh 5vh;
  margin-bottom: 1vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar-carousel span {
  animation: slideChange 5000ms linear infinite;
}

.topbar h2 {
  font-size: 25px;
  padding: 0 5%;
  text-transform: uppercase;
  color: var(--white-color);
  margin: 0px;
}

@media (max-width: 940px) {
  .topbar {
    height: 90vh;
    margin-top: 10vh;
  }
  .topbar h2 {
    font-size: 20px;
  }
  .topbar-carousel {
    font-size: 25px;
    height: 15vh;
  }
}
