@import url("../../variables.css");

.phone-box-container {
  display: inline-block;
}

.phone-box {
  background: var(--primary-green-color-with-opacity);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 7px 20px;
  border-radius: 25px;
}

.phone-box a {
  all: unset;
}

@media (max-width: 1160px) {
  .phone-box-container {
    display: none;
  }
}
