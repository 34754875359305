@import url("../../variables.css");

.button {
  all: unset;
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 300ms;
  background: var(--primary-green-color);
}

.button:hover {
  background: var(--secondary-green-color);
}

.button-opacity {
  background: var(--primary-green-color-with-opacity);
}

.button-opacity:hover {
  background: var(--primary-green-color);
}
