@import url("../../variables.css");

footer {
  background: var(--primary-black-color);
  width: 100%;
  height: auto;
  padding: 5vh 5%;
  box-sizing: border-box;
  border-top: 3px solid var(--primary-green-color);
}

.footer-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.footer-container ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.footer-container ul ol {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.footer-container ul ol:first-child {
  align-items: flex-end;
  padding-right: 30px;
  border-right: 3px solid var(--primary-green-color);
  text-align: right;
}

.footer-container ul ol:last-child {
  align-items: flex-start;
  padding-left: 30px;
  text-align: left;
}

.footer-container ul ol li {
  margin-top: 2px;
}

.footer-container ul ol li:first-child {
  margin-top: 0px;
  padding: 0;
}

.footer-container ul li a {
  all: unset;
  font-size: 13px;
  text-transform: uppercase;
  color: var(--white-color);
  cursor: pointer;
  transition: 300ms;
}

.footer-container ul li a:hover {
  color: var(--primary-green-color);
}

.footer-container p {
  margin-top: 30px;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--white-color);
  transition: 300ms;
}

@media (max-width: 940px) {
  .footer-container ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-container ul ol:first-child {
    align-items: center;
    padding-right: 0px;
    border-right: none;
    text-align: center;
  }

  .footer-container ul ol:last-child {
    align-items: center;
    padding-left: 0px;
    border-right: none;
    text-align: center;
  }

  .footer-container ul ol li {
    margin-top: 5px;
  }
}
