@import url("../../variables.css");

.toggler-active,
.toggler {
  display: none;
}

@media (max-width: 940px) {
  .toggler-active,
  .toggler {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .toggler-active span,
  .toggler span {
    width: 100%;
    border-radius: 5px;
    height: 4px;
    background: var(--black-color);
    transition: 600ms;
  }

  .toggler-active span:nth-child(1) {
    transform: rotate(-45deg);
    position: relative;
    top: 8.5px;
  }

  .toggler-active span:nth-child(2) {
    opacity: 0;
    transform: rotate(45deg);
  }

  .toggler-active span:nth-child(3) {
    transform: rotate(45deg);
    position: relative;
    bottom: 8.5px;
  }
}
