details {
  width: 100%;
  margin: 3vh 0px;
  transition: 300ms;
  text-align: left;
  box-sizing: border-box;
}

details summary {
  width: 100%;
  padding: 20px 25px;
  margin-left: 5px;
  font-weight: 700;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 24px -18px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 24px -18px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 24px -18px rgba(66, 68, 90, 1);
  border-left: 10px solid var(--secondary-green-color);
  box-sizing: border-box;
  cursor: pointer;
  transition: 300ms;
}

details summary:hover {
  border-left: 16px solid var(--secondary-green-color);
}

details p {
  padding: 1px 25px 15px 20px;
  width: 100%;
  box-sizing: border-box;
  color: var(--primary-grey-color);
  text-align: justify;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
