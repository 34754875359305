@import url("../../variables.css");

.description {
  padding: 5vh 5% 0vh 5%;
}

.description h3 {
  text-transform: uppercase;
  font-size: 25px;
  padding: 0;
  margin: 0px;
}

.description h4 {
  margin: 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  color: var(--primary-grey-color);
}
